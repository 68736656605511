import { useState } from 'react';
import { PlayerDetailsSectionProps } from 'screens/CoachPlayersTab/props';
import TabSwitcher from 'components/SkillAnalysis/TabSwitcher';
import { Tabs } from 'components/SkillAnalysis/enums';
import classNames from 'styles/utils/classNames';
import DetailBar from './DetailBar';
import OnCourtDetails from './OnCourt';
import PlayerDetailsSkeleton from './PlayerDetailsSkeleton';
import PlayerHeader from './PlayerHeader';
import RemoteDetails from './Remote';
import AllPlayerSection from '../AllPlayerSection';

const PlayerDetailsSection: React.FC<PlayerDetailsSectionProps> = ({
  selectedPlayerForDetail,
  handleBack,
  handleReflectOnPlayer,
  handleTriggerEditReflection,
  playerDetailData,
  allPlayersDetailData,
  loading,
  isVisibleOnMobile,
  totalPlayers,
}) => {
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.OnCourt);

  if (loading) {
    return <PlayerDetailsSkeleton />;
  }

  return (
    <div className={classNames('min-w-0 flex-1', !isVisibleOnMobile && 'max-md:hidden')}>
      <DetailBar totalPlayers={totalPlayers} handleBack={handleBack} />
      <div className="mx-auto max-w-[51rem] px-4 py-ds-xl">
        <PlayerHeader selectedPlayerForDetail={selectedPlayerForDetail} />
        <TabSwitcher activeTab={activeTab} setActiveTab={setActiveTab} />
        {selectedPlayerForDetail ? (
          <>
            <RemoteDetails data={playerDetailData.remote} visible={activeTab === Tabs.Remote} />
            <OnCourtDetails
              handleReflectOnPlayer={handleReflectOnPlayer}
              handleTriggerEditReflection={handleTriggerEditReflection}
              visible={activeTab === Tabs.OnCourt}
              data={playerDetailData.onCourt}
            />
          </>
        ) : (
          <AllPlayerSection
            handleReflectOnPlayer={handleReflectOnPlayer}
            handleTriggerEditReflection={handleTriggerEditReflection}
            data={allPlayersDetailData}
            activeTab={activeTab}
          />
        )}
      </div>
    </div>
  );
};

export default PlayerDetailsSection;
