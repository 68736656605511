import { Tooltip } from 'react-tooltip';
import { getVideoAnalysisPageUrl } from 'constants/pages';
import { EMPTY_AVATAR_SRC } from 'constants/user';
import {
  FileFormatEnum,
  VideoAnalysis,
  VideoAnalysisReviewStatusesEnum,
} from 'types/generated/client';
import AlertIcon from 'svg/AlertIcon';
import ArrowRight from 'svg/ArrowRight';
import Check from 'svg/Check';
import VideoCamera from 'svg/VideoCamera';
import { VideoAnalysisTableProps } from 'screens/CoachPlayersTab/props';
import { getDifferenceInHours } from 'screens/CoachPlayersTab/utils';
import { getFormattedSkills } from 'screens/VideoAnalysis/utils';
import { ButtonLink } from 'components/Button';
import CustomTable from 'components/CustomTable';
import { CustomTableProps } from 'components/CustomTable/props';
import Pill from 'components/Pill';
import { formatCreationWithIntl } from 'components/Premium/utils';
import classNames from 'styles/utils/classNames';
import { ALERT_THRESHOLD_HOURS } from '../constants';

const VideoAnalysisTable: React.FC<VideoAnalysisTableProps> = ({ data, isAllPlayersInfo }) => {
  const columns: CustomTableProps<VideoAnalysis>['columns'] = [
    {
      title: 'Elapsed',
      columnClassName: 'w-[4rem]',
      render: (row) => {
        const differenceInHours = getDifferenceInHours(row.createdAt);
        return (
          <div className="pl-2">
            {row.reviewStatus === VideoAnalysisReviewStatusesEnum.Reviewed ? (
              <Check className="h-5 w-5 text-brand-green-300" />
            ) : differenceInHours > ALERT_THRESHOLD_HOURS ? (
              <>
                <Tooltip
                  className="!rounded-xl !bg-brand-fire-500"
                  id={`alert-icon-tip-${row.id}`}
                  content={`This player has been waiting for more than ${Math.floor(
                    differenceInHours / 24,
                  )} days. Please provide feedback as quickly as possible!`}
                  place="top"
                />
                <AlertIcon
                  className="text-color-brand-primary"
                  data-tooltip-id={`alert-icon-tip-${row.id}`}
                />
              </>
            ) : (
              <span className="typography-product-caption text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary">
                {differenceInHours}h
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Video',
      columnClassName: 'max-md:hidden w-[9rem]',
      render: (row) => {
        const media = row.videoAnalysisFiles;
        if (
          media.fileFormat === FileFormatEnum.Video ||
          media.fileFormat === FileFormatEnum.Audio
        ) {
          return (
            <video
              controls
              className={classNames(
                'w-full rounded-xl object-cover',
                media.fileFormat === FileFormatEnum.Audio ? 'h-10' : 'h-20',
              )}
            >
              <source src={media.providerUrl} />
              Your browser does not support the video tag.
            </video>
          );
        } else {
          return <img src={media.providerUrl} className="h-20 w-full rounded-xl object-cover" />;
        }
      },
    },
    {
      title: 'Player',
      columnClassName: isAllPlayersInfo ? 'table-cell' : 'hidden',
      render: (row) => (
        <div className={classNames('flex  items-center gap-1')}>
          <img
            className="h-8 w-8 rounded-full"
            src={row.videoAnalysisSubmission?.profileImageProviderUrl || EMPTY_AVATAR_SRC}
            alt="avatar"
          />
          <span className="typography-product-chips-filters capitalize">
            {row.videoAnalysisSubmission?.fullName}
          </span>
        </div>
      ),
    },

    {
      title: 'Date',
      columnClassName: classNames('w-[8rem]', isAllPlayersInfo ? 'max-sm:hidden' : 'table-cell'),
      render: (row) => (
        <span className="typography-product-chips-filters whitespace-nowrap text-color-text-lightmode-secondary dark:text-color-text-lightmode-secondary">
          {formatCreationWithIntl(row.createdAt, false)}
        </span>
      ),
    },

    {
      title: 'Skills',
      columnClassName: 'max-md:hidden',

      render: (row) => {
        const formattedSkills = getFormattedSkills(row?.videoAnalysisRequestedSkillsReviews).slice(
          0,
          3,
        );
        return (
          <div className="flex flex-wrap gap-1">
            {formattedSkills.map((skill, index) => (
              <Pill className="text-center" size="sm" key={`${skill}-${index}`} text={skill} />
            ))}
          </div>
        );
      },
    },

    {
      title: '',
      columnClassName: 'w-[8rem]',
      render: (row) => {
        const isFeedbackPending = row.reviewStatus === VideoAnalysisReviewStatusesEnum.Pending;
        return (
          <ButtonLink
            variant={isFeedbackPending ? 'brand' : 'secondary'}
            className="ml-auto w-full max-w-fit"
            sizeDesktop="sm"
            sizeMobile="xs"
            href={getVideoAnalysisPageUrl(row.id)}
            iconLeft={
              isFeedbackPending ? (
                <VideoCamera className="h-3 w-3 text-color-text-lightmode-invert sm:h-4 sm:w-4" />
              ) : (
                <ArrowRight className="h-3 w-3 sm:h-4 sm:w-4" />
              )
            }
          >
            {isFeedbackPending ? 'Review' : 'Details'}
          </ButtonLink>
        );
      },
    },
  ];

  return (
    <div>
      <h3 className="typography-product-heading-compact-desktop mb-4 text-color-text-lightmode-secondary dark:text-color-bg-darkmode-secondary">
        {isAllPlayersInfo ? 'Outstanding Remote Lessons' : 'Remote Lessons'}
      </h3>
      <CustomTable columns={columns} data={data} />
    </div>
  );
};

export default VideoAnalysisTable;
